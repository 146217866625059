<script lang="ts" setup>
import { useSearch } from './useSearch'

const { modalRef, closeButtonRef, isOpen, close: closeModal, handleKeydown } = useSearchModal()
const { keyword, search: goToSearchPage, getSugesstions, suggestions } = useSearch()

const searchInputRef = ref<HTMLInputElement | null>(null)

watch(keyword, getSugesstions)

const close = () => {
  keyword.value = ''
  closeModal()
}

const search = () => {
  goToSearchPage()
  closeModal()
}

watch(isOpen, (value) => {
  if (value) {
    nextTick(() => {
      keyword.value = ''
      setTimeout(() => {
        searchInputRef.value?.focus()
      }, 100)
    })
  }
})
</script>

<template>
  <Teleport to="body">
    <div
      v-if="isOpen"
      ref="modalRef"
      class="modal modal-search"
      role="dialog"
      aria-modal="true"
      aria-labelledby="search-modal-title"
      @keydown="handleKeydown"
    >
      <div class="box box-with-padding">
        <button ref="closeButtonRef" class="close" type="button" @click="close">
          <Icon name="large-close" />
        </button>

        <h2 id="search-modal-title" class="sr-only">{{ $t('search.title') }}</h2>
        <div class="form">
          <Icon name="search" />
          <input
            ref="searchInputRef"
            v-model="keyword"
            type="text"
            class="text-xlarge f-bold"
            :placeholder="$t('search.form.keywords.placeholder')"
            :aria-label="$t('search.form.keywords.placeholder')"
            @keyup.enter="search"
          />
          <Button v-show="keyword" secondary icon="arrow-left" slide @click="search">
            {{ $t('search.form.button') }}
          </Button>
        </div>
        <ul v-if="suggestions.length" class="suggestions cul">
          <li v-for="suggestion in suggestions" :key="suggestion.id" class="suggestion">
            <NuxtLink class="suggestion-link" :to="suggestion.url" @click="close">
              <span class="hyphenate" v-html="suggestion.text" />
            </NuxtLink>
          </li>
        </ul>
        <Button v-show="keyword" secondary icon="arrow-left" class="search-mobile" slide @click="search">
          {{ $t('search.form.button') }}
        </Button>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 200px;
  max-height: 100%;
  background-color: var(--c-white);
  box-shadow: var(--shadow);
  z-index: 101;
  overflow-y: auto;
}

.box {
  display: grid;
  margin-bottom: 70px;
}

.close {
  justify-self: end;
  width: 30px;
  height: 30px;
  margin-block: 30px 40px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.form {
  display: grid;
  grid-template-columns: auto 1fr;
  @include breakpoint(t) {
    grid-template-columns: auto 1fr auto;
  }
  align-items: center;
  gap: 20px;

  svg {
    color: var(--c-primary);
  }
  input {
    border: none;
    outline: none;
    margin: 0;
    width: 100%;

    @include placeholder {
      opacity: 0.25;
      color: var(--c-primary);
    }
  }
  .button {
    @include breakpoint(t down) {
      display: none;
    }
  }
}

.suggestions {
  margin-left: 42px;
  margin-top: 46px;
}
.suggestion {
  margin-bottom: 16px;
  &-link {
    color: var(--c-primary);
    @include transitions(color);
    @include hover {
      color: var(--c-blue-rollover);
    }
    :deep(em) {
      color: var(--c-primary);
      font-style: normal;
      font-weight: bold;
    }
  }
}

.search-mobile {
  margin-top: 30px;
  justify-self: end;
  @include breakpoint(t) {
    display: none;
  }
}
</style>
